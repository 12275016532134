.rpb-cards {
  .card {
    border:0;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:0rem;
        margin-right:0rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}

.rpb-cards.bootstrap-cards-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  right: 3rem;
  top: -3.5rem;
  left: auto;
}

.rpb-cards.bootstrap-cards-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: 0;
  top: -3.5rem;
}
