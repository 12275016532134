// Theme Colors/Branding
$brand-primary: #2D4A7D;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #d82e1a;
$dark: #000;
$light: #f3f3f3;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #d82e1a;
$accent-2: #d82e1a;
$body-font: #222;
$error: #d81e00;